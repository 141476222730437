<template>
    <div class="input-group input-group-sm">
        <div class="shadow-sm shadow-hover w-100 mt-2 border" :class="{ 'border border-primary bg-light': item.Aberto }" v-for="(item, indexItem) in lista" :key="'item' + indexItem">
            <!-- Loop das localizações -->
            
            <!-- Etapa de PICKING -->
            <div v-if="etapa == EtapaProcesso.PICKING.value">
                <div :class="{ 'fz-80': isMobile }">
                    <!-- Cabeçalho agrupado -->
                    <div v-if="!item.Aberto" :class="item.itemConferido ? 'border-left-success' : 'border-left-pending'">
                        <div class="d-flex flex-column justify-content-between card-header-filter m-0 px-2 py-1 " @click="toggleItemSelecionado(item)">
                            <div class="d-flex justify-content-between w-100">
                                <div>
                                    <div class="d-flex m-0 p-0" v-if="item.DepositoOrigemControlaLocalizacao">
                                        <span class="label-orange font-weight-bold mr-1">{{ item.LocalizacaoPrimaria ? 'LP' : 'LS' }}: </span>
                                        <span class="text-muted">
                                            {{ item.SiglaLocalizacao }}
                                        </span>
                                    </div>
                                    <div class="d-flex m-0 p-0" >
                                        <span class="label-orange font-weight-bold mr-1">Depósito: </span>
                                        <span class="text-muted">
                                            {{ item.IdDepositoOrigem }}- {{item.NomeDepositoOrigem}} 
                                        </span>
                                    </div>                                        
                                </div>

                                <div class="d-flex m-0 p-0">
                                    <span class="label-orange font-weight-bold mr-1">Saldo: </span>
                                    <span class="text-muted">
                                        {{ item.SaldoLocalizacao }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex d-flex justify-content-between" v-if="!item.DepositoOrigemControlaLocalizacao">
                                <span class="label-orange"
                                    >Código: <label class="text-muted p-0 m-0">{{ item.CodigoProduto }}</label></span
                                >
                                <span class="label-orange"
                                    >Referência:<label class="text-muted m-0 p-0">{{ item.Referencia }}</label></span
                                >
                            </div>
                        </div>
                        <!-- quantidades -->
                        <div class="d-flex justify-content-center w-100 font-weight-bold fz-90" @click="toggleItemSelecionado(item)">
                            <div class="mr-auto p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Solicitado</span>
                                        <span>{{ item.QuantidadeSolicitada }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-auto p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Conferido</span>
                                        <span :class="{ 'text-success': item.QuantidadeConferida }">
                                            {{ item.QuantidadeConferida }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Pendente</span>
                                        <span :class="item.QuantidadeConferida - item.QuantidadeSolicitada < 0 ? 'text-danger' : 'text-muted'">
                                            {{ item.QuantidadeConferida - item.QuantidadeSolicitada }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Campo de informação da Requisição  -->
                    <div v-if="item.Aberto">
                        <div class="p-0" v-for="(requisicao, indexReq) in item.Conferencias" :key="'req' + indexReq">
                            <div class="d-flex justify-content-between card-header-filter m-0 p-2" @click="toggleItemSelecionado(item)">
                                <div>
                                    <div class="d-flex m-0 p-0" v-if="item.DepositoOrigemControlaLocalizacao">
                                        <span class="label-orange font-weight-bold mr-1">{{ item.LocalizacaoPrimaria ? 'LP' : 'LS' }}: </span>
                                        <span class="text-muted">
                                            {{ item.SiglaLocalizacao }}
                                        </span>
                                    </div>
                                    <div class="d-flex m-0 p-0" >
                                        <span class="label-orange font-weight-bold mr-1">Depósito: </span>
                                        <span class="text-muted">
                                            {{ item.IdDepositoOrigem }}- {{item.NomeDepositoOrigem}} 
                                        </span>
                                    </div>                                        
                                </div>

                                <div class="d-flex m-0 p-0">
                                    <span class="label-orange font-weight-bold mr-1">Saldo: </span>
                                    <span class="text-muted">
                                        {{ requisicao.SaldoLocalizacao }}
                                    </span>
                                </div>
                            </div>

                            <!-- Descrição do produto:  -->
                            <div v-if="item.Aberto" class="ml-2" @click="toggleItemSelecionado(item)">
                                <div class="text-right d-flex flex-column justify-content-end align-items-start">
                                    <span class="label-orange">{{ item.DescricaoProduto }}</span>
                                    <span class="label-orange"
                                        >Código: <label class="text-muted p-0 m-0">{{ item.CodigoProduto }}</label></span
                                    >
                                    <span class="label-orange"
                                        >Referência:<label class="text-muted m-0 p-0">{{ item.Referencia }}</label></span
                                    >
                                </div>
                                <!-- Descrição da Requisição  -->
                                <div class="text-right d-flex flex-column justify-content-end align-items-start">
                                    <span class="label-orange"
                                        >Requisição: <label class="text-muted p-0 m-0">{{ item.IdsRequisicao[0] }}</label></span
                                    >
                                    <span class="label-orange"
                                        >Data: <label class="text-muted p-0 m-0">{{ $moment(requisicao.DataRequisicao).format('DD/MM/YYYY') }}</label></span
                                    >
                                    <span class="label-orange">
                                        {{ item.IdTipoSolicitacao == 1 ? 'Tipo Requisição' : 'NF/Série' }}:
                                        <label class="text-muted p-0 m-0">
                                            {{ item.IdTipoSolicitacao == 1 ? requisicao.TipoRequisicaoDescricao : requisicao.Documento + '/' + requisicao.Serie }}
                                        </label>
                                    </span>
                                    <span class="label-orange"
                                        >{{ item.IdTipoSolicitacao == 1 ? 'Responsável (Vendedor)' : 'Cliente/Fornecedor' }}:
                                        <label class="text-muted p-0 m-0">{{ item.IdTipoSolicitacao == 1 ? requisicao.VendedorNome : requisicao.NomeClienteFornecedor }}</label></span
                                    >
                                </div>
                                <hr class="my-1 mx-0" />
                            </div>
                            <!-- quantidades -->
                            <div class="d-flex justify-content-center w-100 font-weight-bold fz-90" @click="toggleItemSelecionado(item)">
                                <div class="mr-auto p-2">
                                    <div class="d-flex">
                                        <div class="text-center d-flex flex-column mr-1">
                                            <span class="label-orange">Solicitado</span>
                                            <span>{{ requisicao.QuantidadeSolicitada }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mr-auto p-2">
                                    <div class="d-flex">
                                        <div class="text-center d-flex flex-column mr-1">
                                            <span class="label-orange">Conferido</span>
                                            <span :class="{ 'text-success': requisicao.QuantidadeConferida }">
                                                {{ requisicao.QuantidadeConferida }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-2">
                                    <div class="d-flex">
                                        <div class="text-center d-flex flex-column mr-1">
                                            <span class="label-orange">Pendente</span>
                                            <span :class="requisicao.QuantidadeConferida - requisicao.QuantidadeSolicitada < 0 ? 'text-danger' : 'text-muted'">
                                                {{ requisicao.QuantidadeConferida - requisicao.QuantidadeSolicitada }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- leitor código de barras dos produtos -->
                            <div v-if="item.Aberto && !item.itemConferido" class="input-group input-group-sm shadow-sm m-0 p-1">
                                <vue-numeric-input
                                    v-model="requisicao.QuantidadeEnviar"
                                    type="tel"
                                    align="center"
                                    :min="0"
                                    size="90px"
                                    :value="1"
                                    :max="requisicao.QuantidadeSolicitada - requisicao.QuantidadeConferida"
                                ></vue-numeric-input>
                                <input
                                    v-if="requisicao.DepositoOrigemControlaLocalizacao"
                                    v-model="produtoConferencia"
                                    type="text"
                                    v-focus
                                    class="form-control form-control-sm"
                                    :id="'picking-input-conferencia-item-' + requisicao.IdWMSRequisicaoItem"
                                    @keydown.enter.tab.prevent="conferir(requisicao)"
                                    autocomplete="off"
                                    placeholder="produto"
                                />

                                <div class="input-group-append">
                                    <button class="btn btn-primary btn-sm float-right" @click="conferir(requisicao)">Conferir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Etapa ARMAZENAGEM  -->
            <div v-if="etapa == EtapaProcesso.ARMAZENAGEM.value">
                <!-- <pre>a{{item}}</pre> -->
                <div :class="{ 'fz-80': isMobile }">
                    <div :class="{ 'border-left-success': !item.Aberto &&item.itemConferido, 'border-left-pending': !item.Aberto && !item.itemConferido  }"  >
                        <!-- Descrição do produto:  -->
                        <div class="d-flex flex-column justify-content-end align-items-start text-right card-header-filter m-0 p-2 border-0" @click="toggleItemSelecionado(item)">
                            <span class="label-orange font-weight-bold">{{ item.DescricaoProduto }}</span>
                            <span class="label-orange"
                                >Código: <label class="text-muted p-0 m-0">{{ item.CodigoProduto }}</label></span
                            >
                            <span class="label-orange"
                                >Referência:<label class="text-muted m-0 p-0">{{ item.Referencia }}</label></span
                            >
                        </div>
                        <!-- quantidades -->
                        <div v-if="!item.Aberto" 
                            class="d-flex justify-content-center w-100 font-weight-bold fz-90" 
                            
                            @click="toggleItemSelecionado(item)">
                            <div class="mr-auto p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Separado</span>
                                        <span>{{ item.QuantidadeSeparada }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-auto p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Conferido</span>
                                        <span :class="{ 'text-success': item.QuantidadeConferida }">
                                            {{ item.QuantidadeConferida }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="d-flex">
                                    <div class="text-center d-flex flex-column mr-1">
                                        <span class="label-orange">Pendente</span>
                                        <span :class="item.QuantidadeConferida - item.QuantidadeSeparada < 0 ? 'text-danger' : 'text-muted'">
                                            {{ item.QuantidadeConferida - item.QuantidadeSeparada }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    <div v-if="item.Aberto">
                        <!-- Campo de informação da Requisição  -->
                        <div v-for="(requisicao, indexReq) in item.Conferencias" :key="'req' + indexReq">
                            <div class="text-right d-flex flex-column justify-content-end align-items-start p-2" v-if="requisicao.QuantidadeSeparada">
                                <!-- Descrição da Requisição  -->

                                <div @click="toggleItemSelecionado(item)">
                                    <div class="text-right d-flex flex-column justify-content-end align-items-start">
                                        <span class="label-orange"
                                            >Requisição: <label class="text-muted p-0 m-0">{{ requisicao.IdRequisicao }}</label></span
                                        >
                                        <span class="label-orange"
                                            >Data: <label class="text-muted p-0 m-0">{{ $moment(requisicao.DataRequisicao).format('DD/MM/YYYY') }}</label></span
                                        >
                                        <span class="label-orange"
                                            >Depósito Destino: <label class="text-muted p-0 m-0">{{ requisicao.IdDepositoDestino + '-' + requisicao.NomeDepositoDestino }}</label></span
                                        >
                                        <span class="label-orange">
                                            {{ item.IdTipoSolicitacao == 1 ? 'Tipo Requisição' : 'NF/Série' }}:
                                            <label class="text-muted p-0 m-0">
                                                {{ item.IdTipoSolicitacao == 1 ? requisicao.TipoRequisicaoDescricao : requisicao.Documento + '/' + requisicao.Serie }}
                                            </label>
                                        </span>
                                        <span class="label-orange"
                                            >{{ item.IdTipoSolicitacao == 1 ? 'Responsável (Vendedor)' : 'Cliente/Fornecedor' }}:
                                            <label class="text-muted p-0 m-0">{{ item.IdTipoSolicitacao == 1 ? requisicao.VendedorNome : requisicao.NomeClienteFornecedor }}</label></span
                                        >
                                        
                                    </div>
                                    <hr class="my-1 mx-0" />
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex m-0 p-0">
                                            <span class="label-orange mr-1">{{ requisicao.DepositoControlaLocalizacao ? 'Localização' : 'Depósito' }} Destino: </span>
                                            <span class="text-muted">
                                                {{ requisicao.DepositoControlaLocalizacao ? requisicao.LocalizacaoDestino : requisicao.IdDepositoDestino + '-' + requisicao.NomeDepositoDestino }}
                                            </span>
                                        </div>
                                    </div>
                                    <hr class="my-1 mx-0" />
                                </div>
                                <!-- quantidades -->
                                <div class="d-flex justify-content-center w-100 font-weight-bold fz-90" @click="toggleItemSelecionado(item)">
                                    <div class="mr-auto p-2">
                                        <div class="d-flex">
                                            <div class="text-center d-flex flex-column mr-1">
                                                <span class="label-orange">Separado</span>
                                                <span>{{ requisicao.QuantidadeSeparada }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mr-auto p-2">
                                        <div class="d-flex">
                                            <div class="text-center d-flex flex-column mr-1">
                                                <span class="label-orange">Conferido</span>
                                                <span :class="{ 'text-success': requisicao.QuantidadeConferida }">
                                                    {{ requisicao.QuantidadeConferida }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-2">
                                        <div class="d-flex">
                                            <div class="text-center d-flex flex-column mr-1">
                                                <span class="label-orange">Pendente</span>
                                                <span :class="requisicao.QuantidadeConferida - requisicao.QuantidadeSeparada < 0 ? 'text-danger' : 'text-muted'">
                                                    {{ requisicao.QuantidadeConferida - requisicao.QuantidadeSeparada }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- leitor código de barras dos produtos -->
                                <div v-if="!item.itemConferido" class="input-group input-group-sm shadow-sm m-0 p-0">
                                    <vue-numeric-input
                                        v-model="requisicao.QuantidadeEnviar"
                                        type="tel"
                                        align="center"
                                        :min="0"
                                        size="90px"
                                        :value="1"
                                        :max="requisicao.QuantidadeSeparada - requisicao.QuantidadeConferida"
                                    ></vue-numeric-input>

                                    <input
                                        v-if="requisicao.DepositoControlaLocalizacao"
                                        v-model="localizacaoConferencia"
                                        type="text"
                                        v-focus
                                        class="form-control form-control-sm"
                                        :id="'input-conferencia-' + requisicao.IdWMSRequisicaoItem"
                                        @keydown.enter.tab.prevent="conferir(requisicao)"
                                        autocomplete="off"
                                        :placeholder="descricaoCampoConferir"
                                    />

                                    <div class="input-group-append">
                                        <button class="btn btn-primary btn-sm float-right" @click="conferir(requisicao)"
                                        :id="'btn-input-conferencia-item-' + requisicao.IdWMSRequisicaoItem"
                                        >Conferir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from '@/common/alerts';
import EtapaProcesso from '@/domain/etapa-processo';

export default {
    name: 'CardRequisicoesMobile',
    props: ['lista', 'etapa', 'itemSelecionado', 'itemBipado'],
    data: function () {
        return {
            EtapaProcesso,
            produtoConferencia: '',
            localizacaoConferencia: ''
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            videoDevice: 'uiState/hasVideoDevice'
        }),
        descricaoQuantidadeSolicitada: function () {
            return this.etapa == EtapaProcesso.PICKING.value ? 'Qtde. Solicitada' : 'Qtde. Separada';
        },
        descricaoCampoConferir: function () {
            return this.etapa == EtapaProcesso.PICKING.value ? 'Bipar Produto' : 'Bipar Localização';
        }
    },

    filters: {
        datePtBr: function (value) {
            return this.$moment(value).format('DD/MM/YYYY');
        }
    },

    methods: {
        limparConferidos: function (item) {
            this.$emit('limpar-conferidos', item);
        },

        conferir: function (item) {
            var self = this;
            
            if (self.etapa == EtapaProcesso.PICKING.value) {
                if (!self.produtoConferencia && item.DepositoOrigemControlaLocalizacao) {
                    swal.exibirMensagemAlerta('Informe o Produto a ser conferido.');
                    return;
                } else if (self.produtoConferencia && self.produtoConferencia != item.CodigoProduto && self.produtoConferencia != item.Referencia) {
                    swal.exibirMensagemAlerta(`O produto bipado: ${self.produtoConferencia}  não pertence a esta requisição.`);
                    return;
                }

                if(item.DepositoOrigemControlaLocalizacao){
                    item.LocalizacaoConfirmada = self.itemBipado.length ? self.itemBipado : item.SiglaLocalizacaoAtual;
                    self.produtoConferencia = '';
                    self._setarFocusCampoConferir(item.IdWMSRequisicaoItem);
                }
            } else if (self.etapa == EtapaProcesso.ARMAZENAGEM.value  && item.DepositoControlaLocalizacao) {
                if (item.DepositoControlaLocalizacao) {
                    if (!self.localizacaoConferencia) {
                        swal.exibirMensagemAlerta('A localização do item deve ser informada.');
                        return;
                    } else if (self.localizacaoConferencia.trim() == item.SiglaLocalizacaoAtual && item.IdTipoSolicitacao == 1) {
                        swal.exibirMensagemAlerta('A localização informada não pode ser a mesma da localização atual.');
                        return;
                    }
                }
                item.LocalizacaoConfirmada = self.localizacaoConferencia ? self.localizacaoConferencia : item.SiglaLocalizacaoDestino;
                self.localizacaoConferencia = '';
            }

            if (self.quantidadeInvalida(item)) {
                swal.exibirMensagemAlerta('A quantidade para a conferência não é válida. Reveja e tente novamente.');
                return;
            }

            self.$emit('conferir', item);
        },

        quantidadeSolicitada: function (item) {
            // retorna a qtde separada no caso de armazenagem
            return this.etapa == EtapaProcesso.PICKING.value ? item.QuantidadeSolicitada : item.QuantidadeSeparada;
        },
        _setarFocusCampoConferir(idCampo) {
            document.getElementById(`picking-input-conferencia-item-${idCampo}`).focus();
        },
        _setarFocusBtnConferir(idCampo) {
            document.getElementById(`btn-input-conferencia-item-${idCampo}`).focus();
        },
        toggleItemSelecionado(item) {
            if (item.Aberto) {
                item.Aberto = false;
                document.getElementById('input-localizar').focus();
            } else this._abrirItemSelecionado(item);
        },

        _abrirItemSelecionado(item) {
            var self = this;
            var itemAberto = self.lista.find(function (x) {
                return x.Aberto && x.Index !== item.Index;
            });
            if (itemAberto) itemAberto.Aberto = false;
            // isso precisa ser feito
            // quando tem duas atualizações na lista
            self.$nextTick(function () {
                if (item) {
                    item.Aberto = true;
                    if (self.etapa == EtapaProcesso.ARMAZENAGEM.value && !item.itemConferido)  self._setarFocusBtnConferir(item.Conferencias[0].IdWMSRequisicaoItem)
                    self.$emit('onRequisicaoselecionada', item);
                }
            });
        },

        quantidadeInvalida: function (item) {
            if (this.etapa == EtapaProcesso.PICKING.value) return item.QuantidadeEnviar > item.QuantidadeSolicitada - item.QuantidadeConferida;
            else return item.QuantidadeEnviar > item.QuantidadeSeparada - item.QuantidadeConferida;
        }
    }
};
</script>

<style scope>
#style1.vue-numeric-input.updown {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
#style1.vue-numeric-input.updown .numeric-input {
    padding-right: 5px !important;
    padding-left: 5px !important;
}
#style1.vue-numeric-input.updown .btn {
    background: #6fbbff !important;
}
.card-header-filter {
    /* margin-top: 58px; */
    margin-top: 0px;
    background-color: #f9f9f9;
}
.border-left-success {
    border-left: 5px solid  #34ab56!important;
}
.border-left-pending {
    border-left: 5px solid  #5c9bdc!important;
}
</style>
